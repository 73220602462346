/* eslint-disable func-names */
/* eslint-disable class-methods-use-this */
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import dashboardStyle from '../../assets/jss/views/dashboardStyle';
import Typography from '@material-ui/core/Typography';


class LoadingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.currentState = 'loading';
  }
  render() {
    return (
      <div>
        <Typography variant="h6">{this.props.message}</Typography>
        <CircularProgress />
      </div>
    )
  }
}

export default withTranslation()(withStyles(dashboardStyle)(LoadingScreen));
